const url = "https://linetest.zpro.app/cookietest.php?_t=" + Date.now();
fetch(url, {
  method: "GET",
  mode: 'cors',
  credentials: 'include', // cookie送受信を許可
})
.then(response => {
  return response.text();
})
.then(text => {
  console.log("fetch");
  console.log(text);
})
.catch(err => {
  console.log("CATCH");
  console.error(err);
});

const xhr = new XMLHttpRequest();
xhr.open("GET", url);
xhr.withCredentials = true; // cookieを許可
xhr.send();
xhr.onload = ()=> {
  console.log("XHR");
  console.log(xhr.response);
};